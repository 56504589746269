import {mapGetters, mapMutations} from "vuex";
import {defineComponent} from 'vue';
import ApiService from '@/services/api.service';
import {PRODUCT_DETAIL_API_PATH} from "@/constants/api.constant";

export default defineComponent({
  computed: {
    ...mapGetters('product', ['getScannedProduct']),

    scannedProduct() {
      return this.getScannedProduct || {};
    },

    scannedProductWebshopProperties() {
      if (!this.scannedProduct.customFields) {
        return [];
      }

      const properties = this.scannedProduct.customFields['K_WebshopEigenschaften'];

      if (!properties) {
        return [];
      }

      return properties.split('|')
    },

    scannedProductPZN() {
      if (!this.scannedProduct.customFields) {
        return '';
      }

      return this.scannedProduct.customFields['PZN'];
    },

    scannedProductCoverFirstThumbnailUrl() {
      if (!this.scannedProduct.media) {
        return '';
      }

      return `data:image/png;base64, ${this.scannedProduct.media}`
    }
  },

  methods: {
    //TODO for testing
    ...mapMutations('product', ['setScannedProduct']),

    async loadProductFromApi(id) {
      const loading = await this.presentScreenLoading();

      try {
        const response = await ApiService.get(PRODUCT_DETAIL_API_PATH(id));
        const product = response.data.product;
        this.dismissScreenLoading(loading);

        this.setScannedProduct(product);
        console.log(this.scannedProduct);
      } catch (e) {
        console.log(e);
        this.dismissScreenLoading(loading);
        await this.openToast('Produkt nicht gefunden', 'danger', 4000);
      }
    },
    //TODO end for testing
  }
});
