<template>
  <div class="sm-shopping-footer">
    <div class="sm-shopping-footer-content">
      <div class="sm-line-item-quantity">
        <ion-input v-model="quantity" type="number" min="1" max="99" autocorrect="on"></ion-input>
      </div>
      <div class="sm-line-item-cart">
        <ion-button class="sm-shopping-footer__btn-buy" @click="addToCart" color="primary">IN DEN WARENKORB</ion-button>
      </div>
      <div class="sm-line-item-scan">
        <ion-button @click="() => router.push({name: 'scan-qr'})" color="secondary">ZURÜCK</ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonInput, IonButton, modalController } from '@ionic/vue';
import {useRouter} from "vue-router";
import ModalProductAdded from '@/components/cart/modal-product-added';
import {mapGetters} from "vuex";
import ScreenLoadingMixin from "@/mixins/screen-loading.mixin";
import CommonMixin from "@/mixins/common.mixin";
import CartService from '@/services/cart.service';
import EventBus from '@/services/event.service';

export default defineComponent({
  name: "SmShoppingFooter",

  components: {
    IonInput,
    IonButton,
  },

  mixins: [ScreenLoadingMixin, CommonMixin],

  setup() {
    const router = useRouter();
    return { router };
  },

  created() {
    EventBus().emitter.on('onDismissModalProductAdded', this.onDismissModal);
  },

  computed: {
    ...mapGetters('product', ['getScannedProduct', 'isFreeProduct', 'getFreeProductRequest']),
    ...mapGetters('auth', ['getCustomer'])
  },

  data() {
    return {
      quantity: 1,
      modal: null,
    }
  },

  methods: {
    async addToCart() {
      const loading = await this.presentScreenLoading();

      try {
        if (this.isFreeProduct && !this.getFreeProductRequest) {
          EventBus().emitter.emit("throwRequireFreeProductRequestMessage");
          this.dismissScreenLoading(loading);

          return;
        }
        // add to storage
        const lineItem = this.createLineItem();
        await CartService.addLineItemQuantity(this.getCustomer.id, lineItem);
        this.dismissScreenLoading(loading);
        // if success, open modal
        await this.openModal();
        this.quantity = 1;
      } catch (e) {
        console.log(e);
        // if failed, open warning toast
        this.dismissScreenLoading(loading);
        this.quantity = 1;
        await this.openToast('Something went wrong!', 'danger');
      }
    },

    createLineItem() {
      const product = this.getScannedProduct;
      let lineItem = {
        id: product.id,
        referencedId: product.id,
        label: product.name,
        quantity: parseInt(this.quantity),
        type: "product",
        removable: true,
        stackable: true,
        modified: true,
        customProductNo : product.customProductNo
      }

      if (this.isFreeProduct) {
        lineItem.freeArticle = true;
        lineItem.freeArticleRequestMessage = this.getFreeProductRequest;
      } else {
        lineItem.freeArticle = false;
        lineItem.freeArticleRequestMessage = '';
      }

      return lineItem;
    },

    async openModal() {
      const modal = await modalController
        .create({
          component: ModalProductAdded,
          cssClass: 'sm-modal-product-added',
          showBackdrop: true,
          backdropDismiss: false,
          componentProps: {
            quantity: this.quantity
          },
        })

      await modal.present();

      this.modal = modal;
    },

    async onDismissModal() {
      await this.modal.dismiss();
      this.modal = null;
    }
  }
});
</script>

<style scoped>
  .sm-shopping-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0 1px 18px rgba(0, 0, 0, 0.18);
    width: 100%;
  }

  .sm-shopping-footer-content {
    padding: 1rem;
    display: flex;
    background: var(--ion-color-primary-contrast);
    justify-content: space-between;
  }

  .sm-shopping-footer-content ion-input{
    max-width: 3.4rem;
    height: 100%;
    align-items: center;
    --color: var(--ion-color-dark);
  }

  .sm-shopping-footer__btn-buy {
    flex: 1;
  }

  .sm-shopping-footer .native-input {
    height: 38px;
  }

  .sm-line-item-quantity {
    min-width: 2rem;
    margin-right: 0.5rem;
  }

  .sm-line-item-scan {
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 375px) {
    .sm-shopping-footer ion-button {
      --padding-start: 0.5rem;
      --padding-end: 0.5rem;
      font-size: 13px;
    }
  }
</style>
