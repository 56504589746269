<template>
  <ion-page class="sm-shopping-layout ion-page">
  <ion-app>

      <sm-header></sm-header>

      <ion-content scroll="true" class="sm-app-content" fullscreen>
        <slot></slot>
      </ion-content>

      <sm-shopping-footer></sm-shopping-footer>
  </ion-app>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import SmHeader from "../common/SmHeader";
import SmShoppingFooter from "../common/SmShoppingFooter";

export default defineComponent({
  name: "SmMasterLayout",

  components: {
    IonApp,
    IonContent,
    IonPage,
    SmHeader,
    SmShoppingFooter
  },
})
</script>

<style scoped>

</style>
