<template>
  <div class="ion-padding modal-content">
    <div class="sm-modal-product-added-message">
      <ion-icon :icon="checkmarkCircle" color="success" slot="end"></ion-icon>
      <h3>ARTIKEL DEM WARENKORB HINZUGEFÜGT</h3>
    </div>

    <div class="sm-modal-product-added-info">
      <div class="sm-modal-product-added-image">
        <img v-if="!scannedProductCoverFirstThumbnailUrl" src="img/placeholder.svg" alt="Blank image">
        <img v-else :src="scannedProductCoverFirstThumbnailUrl" alt="Article cover image" />
      </div>
      <div class="sm-modal-product-added-description">
        <h3>{{ scannedProduct.name }}</h3>
        <p v-if="scannedProduct.customProductNo">Produkt-Nr.: {{ scannedProduct.productNo }} ({{ scannedProduct.customProductNo }})</p>
        <p v-else>Produkt-Nr.: {{ scannedProduct.productNo }}</p>
        <p>Anzahl: {{ quantity }}</p>
      </div>
    </div>

    <div class="sm-modal-question">
      Wie geht es weiter?
    </div>

    <div class="sm-modal-bottom">
      <div class="sm-modal-btn-checkout">
        <ion-button @click="toCheckoutAddress" expand="block" color="primary">zur kasse</ion-button>
      </div>
      <div class="sm-modal-btn-shopping-cart">
        <ion-button @click="toShoppingCart" expand="block" color="secondary">zum warenkorb</ion-button>
      </div>
      <div class="sm-modal-btn-qr-code">
        <ion-button @click="toScanQr" expand="block" fill="outline" color="secondary">weiteren artikel scannen</ion-button>
      </div>
    </div>

  </div>
</template>

<script>
import { IonIcon, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import {useRouter} from "vue-router";
import {checkmarkCircle} from "ionicons/icons";
import ScannedProductMixin from "@/mixins/scanned-product.mixin";
import EventBus from '@/services/event.service';

export default defineComponent({
  name: 'ModalProductAdded',

  props: {
    quantity: {
      type: Number,
      default: 1,
    }
  },

  mixins: [ScannedProductMixin],

  components: { IonIcon, IonButton },

  setup() {
    const router = useRouter();
    return { router, checkmarkCircle };
  },

  data() {
    return {
      content: 'Content',
    }
  },

  methods: {
    toScanQr() {
      EventBus().emitter.emit("onDismissModalProductAdded");
      this.router.push({name: 'scan-qr'})
    },

    toShoppingCart() {
      EventBus().emitter.emit("onDismissModalProductAdded");
      this.router.push({name: 'shopping-cart'})
    },

    toCheckoutAddress() {
      EventBus().emitter.emit("onDismissModalProductAdded");
      this.router.push({name: 'addresses'})
    }
  }
});
</script>

<style>
.sm-modal-btn-qr-code,
.sm-modal-btn-shopping-cart {
  margin-top: 12px;
}

.sm-modal-btn-shopping-cart,
.sm-modal-btn-checkout {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.sm-modal-product-added-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sm-modal-product-added-message ion-icon {
  font-size: 1.2rem;
}

.sm-modal-product-added-message h3 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding-left: 0.5rem;
}

.sm-modal-product-added-info {
  display: flex;
  padding: 1.5rem;
}

.sm-modal-product-added-image {
  width: 30%;
}

.sm-modal-product-added-image img {
  width: 70px;
}

.sm-modal-product-added-description {
  width: 70%;
}

.sm-modal-product-added-description h3 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  /*height: 2.5rem;*/
  /*word-break: break-all;*/
  /*display: -webkit-box;*/
  /*-webkit-line-clamp: 2;*/
  /*-webkit-box-orient: vertical;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
}

.sm-modal-product-added-description p {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.sm-modal-question {
  text-align: center;
  padding: 1.5rem 0;
  border-top: 1px solid #ccc;
}
</style>
